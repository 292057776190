//
//
//
//
//
//
//
//
//
//
//
//

import StoreFilter from "@/components/pages/store/StoreFilter/index";
import StoreList from "@/components/pages/store/StoreList/index";
import StorePagination from "@/components/pages/store/StorePagination/index";
import categoryPageEvent from "~/assets/js/eSputnik/categoryPageEvent";
import StoreFilterMinimal from "~/components/pages/store/StoreFilterMinimal";
import { mapGetters } from "vuex";
import StoreSubmenu from "~/components/pages/store/StoreSubmenu";
import FeatureProductWidget from "@/widgets/store/Catalog/FeatureProducts/index";
import { debounce } from 'lodash';
import searchRequestEvent from '~/assets/js/eSputnik/searchRequestEvent';

const debouncedEsEvent = debounce(searchRequestEvent,500);

export default {
    name: "Catalog",
    components: {
        StoreSubmenu,
        StoreFilter,
        StorePagination,
        StoreList,
        StoreFilterMinimal,
        FeatureProductWidget,
    },
    computed: {
        ...mapGetters({
            products: "catalogStore/getProducts",
            pageH1: "dynamicPage/pageH1",
            breadcrumbsData: "dynamicPage/breadcrumbsData",
            pageStructure: "dynamicPage/getMicroPageStructure"
        }),
        isSearch() {
            return this.route.includes("/search/") || this.$route.path.includes("/search?");
        },
        searchParams() {
            return _get(this.$route, 'query.s', '');
        },
        route() {
            return this.$route.fullPath;
        },
        catalogSubmenuWidget() {
            return this.pageStructure.find(w => w.name === "widget_submenu")
        },
        hasCatalogSubmenu() {
            return  _get(this, 'catalogSubmenuWidget.data.items', []).length > 0;
        }
    },

    watch: {
        route: {
            handler(newVal, oldVal) {
                if (process.browser && (JSON.stringify(newVal) !== JSON.stringify(oldVal)) && this.products.length > 0) {
                    let arr = [];
                    this.products.map((el) => {
                        el.sizes.map(size => {
                            arr.push({
                                id: size.id,
                                google_business_vertical: "retail"
                            });
                        });
                        return arr;
                    });


                    if (this.searchParams) {
                        debouncedEsEvent({
                            search: this.searchParams,
                            isFound: this.products.length > 0 ? 1 : 0,
                        });
                    }

                    this.$gtag("event", this.isSearch ? "view_search_results" : "view_item_list", {
                        items: arr,
                    });
                }
            },
            immediate: true
        },
    },

    mounted() {
        if (process.browser) {
            categoryPageEvent({ breadcrumbs: this.breadcrumbsData, pageTitle: this.pageH1 });
        }
    }
};
